/* You can add global styles to this file, and also import other style files */
@import 'buttons';
@import 'dialogs';
@import 'material';

body {
    font-family: 'Inter', sans-serif;
    font-optical-sizing: auto;
    font-weight: 400;
    font-style: normal;
    font-variation-settings: 'slnt' 0;
}
