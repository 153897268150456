@import '../variables/colors.scss';

@keyframes bx-dialog-pulse {
    0% {
        opacity: 1;
    }
    50% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

.bx-dialog-pulsate {
    animation: bx-dialog-pulse 1s infinite;
}

.bx-dialog {
    border-radius: 7px !important;
    padding: 64px !important;
    width: 38rem !important;

    .swal2-icon {
        display: none !important;
    }
    .swal2-header {
        align-items: flex-start !important;
    }
    .swal2-title {
        font-weight: 400 !important;
        font-size: 21px !important;
        text-align: left !important;
        padding: 0;
    }

    .swal2-content {
        text-align: left !important;
        font-size: 16px !important;
        line-height: 23px !important;
        margin: 10px 0 !important;
    }
    .swal2-actions {
        justify-content: flex-end !important;
        width: 100%;
        button {
            font-size: 14px !important;
        }
    }
    .swal2-cancel {
        background: none !important;
        color: #545454 !important;
    }
    .swal2-styled {
        padding: 8px 12px !important;
        font-weight: 600 !important;
        &:focus {
            outline: none !important;
            box-shadow: none !important;
        }
        &:hover {
            background: none;
            background-image: none !important;
        }
    }
    .swal2-checkbox {
        justify-content: flex-start;
        input[type='checkbox']:checked + span.swal2-label {
            a {
                color: $color-mango;
            }
        }
    }
    .swal2-validation-message {
        background-color: transparent;
        color: $color-mango-border;
        justify-content: flex-start;
        font-size: 14px;
        padding: 0 64px;

        &::before {
            display: none;
        }
    }
    h1,
    h2 {
        font-family: 'Inter', sans-serif !important;
    }
    .swal2-html-container {
        text-align: left;
        margin: 2em 0;
    }

    /* Colours */
    &-error,
    &-warning {
        border: 1px solid $color-mango-border !important;
        .swal2-title {
            color: $color-mango-border !important;
        }
        .swal2-confirm {
            background-color: $color-mango-border !important;
            border-left-color: $color-mango-border !important;
            border-right-color: $color-mango-border !important;
        }
    }

    &-success {
        border: 1px solid $color-primary-green !important;
        .swal2-title {
            color: $color-primary-green !important;
        }
        .swal2-confirm {
            background-color: $color-primary-green !important;
            border-left-color: $color-primary-green !important;
            border-right-color: $color-primary-green !important;
        }
    }

    &-info,
    &-confirm {
        border: 1px solid $color-mango !important;
        .swal2-title {
            color: $color-mango !important;
        }
        .swal2-confirm {
            background-color: $color-mango !important;
            border-left-color: $color-mango !important;
            border-right-color: $color-mango !important;
        }
    }

    a.swal-link {
        color: inherit;
        text-decoration: underline !important;

        &:hover {
            color: inherit;
        }
    }
}
