@import '../variables/colors.scss';
@import '../variables/colors_v2.scss';

$color-primary-button: $color-primary-green;
$color-primary-button-hover: $color-primary-green-dark;
$color-secondary-button: $button-grey;
$color-secondary-button-hover: $color-nav-v2-buttons-active;
$color-alert-button: $color-mango-border;
$color-alert-button-hover: $color-mango-dark;
$color-hyperlink-hover: $color-mango;
$font-family: 'Inter', sans-serif;

%button-common {
    color: #fff;
    border-radius: 4px;
    font-family: $font-family;
    padding: 6px 10px;
    font-size: 14px;
    font-weight: 500;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    box-shadow: none;
    transition: all 0.3s ease;
    border: solid 1px transparent;
    box-sizing: border-box;
    position: relative;
    outline: 0;
    // bootstrap btn styles
    display: inline-block;
    margin-bottom: 0;
    white-space: nowrap;
    vertical-align: middle;
    touch-action: manipulation;
    cursor: pointer;
    user-select: none;
}

/** Primary buttons **/
.btn-bx-primary {
    @extend %button-common;
    background: $color-primary-button;
    &:hover,
    &:active,
    &:focus {
        background: $color-primary-button-hover;
        color: $color-white;
    }
    &[disabled] {
        background: $color-primary-button;
        opacity: 0.5;
        cursor: not-allowed;
    }
}
a.btn-bx-primary.disabled {
    background-color: $color-disabled-grey;
    cursor: not-allowed;
    opacity: 0.5;
}

/** Secondary buttons **/
.btn-bx-secondary {
    @extend %button-common;
    background: $color-secondary-button;
    &:hover,
    &:active,
    &:focus {
        background: $color-secondary-button-hover;
        color: $color-white;
    }
    &[disabled] {
        background: $color-disabled-grey;
        cursor: not-allowed;
    }
}
a.btn-bx-secondary.disabled {
    background-color: $color-disabled-grey;
    cursor: not-allowed;
    opacity: 0.5;
}

/** Secondary Stroked buttons **/
.btn-bx-secondary-stroked {
    @extend %button-common;
    background: $color-white;
    color: $button-secondary-stroked-text;
    border-color: $button-secondary-stroked-border;
    &:hover,
    &:active {
        background: $button-secondary-stroked-hover;
        color: $button-secondary-stroked-text;
    }
    &[disabled] {
        background: $color-white;
        cursor: not-allowed;
        opacity: 0.5;
    }
}

.btn-bx-secondary-stroked-transparent {
    @extend .btn-bx-secondary-stroked;
    border-color: transparent;
}

/** Secondary button for dark background **/
.btn-bx-secondary-light {
    @extend %button-common;
    background: $color-white;
    color: $button-grey;
    border-color: $color-disabled-grey;
    &:hover,
    &:active,
    &:focus {
        background: $color-disabled-grey;
        color: $button-grey;
    }
    &[disabled] {
        background: $color-disabled-grey;
        cursor: not-allowed;
    }
}

/** Alert buttons **/
.btn-bx-alert {
    @extend %button-common;
    background: $color-alert-button;
    &:hover,
    &:active {
        background: $color-alert-button-hover;
        color: $color-white;
    }
    &[disabled] {
        background: $color-disabled-grey;
        cursor: not-allowed;
    }
}

/** Hyperlink mixin **/
@mixin bx-hyperlink($color) {
    @extend %button-common;
    background: transparent;
    text-decoration: underline;
    color: $color;
    padding: 0;
    &:hover,
    &:focus,
    &:active {
        color: $color-hyperlink-hover;
        text-decoration: underline;
    }
    &[disabled] {
        color: $color-disabled-grey;
        cursor: not-allowed;
    }
}
/** Hyperlink button **/
.btn-bx-hyperlink {
    @include bx-hyperlink($color-secondary-button);
}

/** Hyperlink White button **/
.btn-bx-hyperlink-white {
    @include bx-hyperlink(#fff);
}

//large buttons
.btn {
    &-large {
        padding: 10px 16px;
    }
}

.btn-bx-panel-action {
    @extend %button-common;
    color: $sem-color-neutral-strong;
    border-radius: 21px;
    border: 1px solid $sem-color-outline-moderate;
    background: $sem-color-background-clear;
    text-transform: uppercase;

    &:hover,
    &:focus,
    &:active {
        border: 1px solid $sem-color-primary-tint-strong;
        background: $sem-color-primary-tint-weak;
    }

    &[disabled] {
        border: 1px solid $sem-color-outline-moderate;
        background: $sem-color-background-clear;
        opacity: 0.6;
        cursor: not-allowed;
    }
}
