//------------------------------------------------------------------------------------------------------------
// IMPORTANT: Colours are defined in Figma
// https://www.figma.com/file/TVeXlSM4aRJNuVsMhduIjc/Buildxact-Web-Color-System?node-id=80%3A16825&mode=dev
//------------------------------------------------------------------------------------------------------------

//-----------------------------------
// Base colors
//-----------------------------------
$base-transparent: #ffffff;

$base-blue-25: #f3f7fc;
$base-blue-50: #e6eef9;
$base-blue-100: #b0cbee;
$base-blue-200: #8ab1e5;
$base-blue-300: #548ed9;
$base-blue-400: #3378d2;
$base-blue-500: #0056c7;
$base-blue-600: #004eb5;
$base-blue-700: #0707b2;
$base-blue-800: #06068c;
$base-blue-900: #040459;

$base-navy-25: #f4f7f8;
$base-navy-50: #eaeff2;
$base-navy-100: #bfcfd6;
$base-navy-200: #a0b7c2;
$base-navy-300: #7496a7;
$base-navy-400: #598295;
$base-navy-500: #30637b;
$base-navy-600: #2c5a70;
$base-navy-700: #224657;
$base-navy-800: #1a3644;
$base-navy-900: #142a34;

$base-orange-25: #fff9f4;
$base-orange-50: #fef4e9;
$base-orange-100: #fcdeba;
$base-orange-200: #fbcd98;
$base-orange-300: #f9b76a;
$base-orange-400: #f8a94d;
$base-orange-500: #f69320;
$base-orange-600: #e0861d;
$base-orange-700: #af6817;
$base-orange-800: #875112;
$base-orange-900: #673e0d;

$base-yellow-25: #fffbf3;
$base-yellow-50: #fff8e7;
$base-yellow-100: #feeab6;
$base-yellow-200: #fedf92;
$base-yellow-300: #fed160;
$base-yellow-400: #fdc841;
$base-yellow-500: #fdba12;
$base-yellow-600: #e6a910;
$base-yellow-700: #b4840d;
$base-yellow-800: #8b660a;
$base-yellow-900: #6a4e08;

$base-neutral-0: #ffffff;
$base-neutral-25: #f6f6f6;
$base-neutral-50: #eceded;
$base-neutral-75: #e1e3e3;
$base-neutral-100: #c4c6c8;
$base-neutral-200: #a8aaad;
$base-neutral-300: #808488;
$base-neutral-400: #676c71;
$base-neutral-500: #41474d;
$base-neutral-600: #3b4146;
$base-neutral-700: #2e3237;
$base-neutral-800: #24272a;
$base-neutral-900: #1b1e20;

$base-red-25: #fdf6f6;
$base-red-50: #fceded;
$base-red-100: #f5c8c8;
$base-red-200: #f0adad;
$base-red-300: #ea8888;
$base-red-400: #e57171;
$base-red-500: #df4d4d;
$base-red-600: #cb4646;
$base-red-700: #9e3737;
$base-red-800: #7b2a2a;
$base-red-900: #5e2020;

$base-green-25: #f6fbf7;
$base-green-50: #edf6ef;
$base-green-100: #c6e3ce;
$base-green-200: #aad5b6;
$base-green-300: #83c294;
$base-green-400: #46a460;
$base-green-500: #409557;
$base-green-600: #327444;
$base-green-700: #275a35;
$base-green-800: #1d4528;
$base-green-900: #143a1e;

$base-teal-25: #f6fbf7;
$base-teal-50: #e6f0ee;
$base-teal-100: #b1d0cb;
$base-teal-200: #8bbab1;
$base-teal-300: #569a8e;
$base-teal-400: #358678;
$base-teal-500: #036856;
$base-teal-600: #035f4e;
$base-teal-700: #024a3d;
$base-teal-800: #02392f;
$base-teal-900: #012c24;

$base-purple-25: #f2f2f8;
$base-purple-35: #ececf5;
$base-purple-50: #e4e4f8;
$base-purple-100: #d0d0f8;
$base-purple-200: #a9a9f8;
$base-purple-300: #8181f8;
$base-purple-400: #6666de;
$base-purple-500: #4747cc;
$base-purple-600: #4747b2;
$base-purple-700: #30308c;
$base-purple-800: #323273;
$base-purple-900: #303059;

//-----------------------------------
// Semantic colors
//-----------------------------------
$sem-color-primary-weak: $base-teal-400;
$sem-color-primary-moderate-default: $base-teal-500;
$sem-color-primary-strong: $base-teal-600;
$sem-color-primary-tint-weak: $base-teal-50;
$sem-color-primary-tint-moderate: $base-teal-100;
$sem-color-primary-tint-strong: $base-teal-200;
$sem-color-primary-strongest: $base-teal-800;

$sem-color-secondary-weak: $base-navy-400;
$sem-color-secondary-moderate-default: $base-navy-500;
$sem-color-secondary-strong: $base-navy-600;
$sem-color-secondary-tint-weakest: $base-navy-25;
$sem-color-secondary-tint-weak: $base-navy-50;
$sem-color-secondary-tint-moderate: $base-navy-100;
$sem-color-secondary-tint-strong: $base-navy-200;
$sem-color-secondary-strongest: $base-navy-800;

$sem-color-neutral-weakest: $base-neutral-400;
$sem-color-neutral-weak: $base-neutral-500;
$sem-color-neutral-moderate-default: $base-neutral-600;
$sem-color-neutral-strong: $base-neutral-700;
$sem-color-neutral-tint-weakest: $base-neutral-25;
$sem-color-neutral-tint-weak: $base-neutral-50;
$sem-color-neutral-tint-moderate: $base-neutral-100;
$sem-color-neutral-tint-strong: $base-neutral-200;
$sem-color-neutral-strongest: $base-neutral-800;

$sem-color-success-moderate-default: $base-green-400;
$sem-color-success-strong: $base-green-500;
$sem-color-success-tint-weak: $base-green-50;
$sem-color-success-tint-moderate: $base-green-100;
$sem-color-success-strongest: $base-green-800;

$sem-color-error-moderate-default: $base-red-500;
$sem-color-error-strong: $base-red-700;
$sem-color-error-tint-weak: $base-red-50;
$sem-color-error-tint-moderate: $base-red-100;
$sem-color-error-strongest: $base-red-800;

$sem-color-accent-1-weak: $base-blue-400;
$sem-color-accent-1-moderate: $base-blue-500;
$sem-color-accent-1-strong: $base-blue-600;
$sem-color-accent-1-tint-weak: $base-blue-50;
$sem-color-accent-1-tint-moderate: $base-blue-100;
$sem-color-accent-1-tint-strong: $base-blue-200;
$sem-color-accent-1-strongest: $base-blue-700;

$sem-color-accent-2-weak: $base-orange-400;
$sem-color-accent-2-moderate: $base-orange-500;
$sem-color-accent-2-strong: $base-orange-600;
$sem-color-accent-2-tint-weak: $base-orange-50;
$sem-color-accent-2-tint-moderate: $base-orange-100;
$sem-color-accent-2-tint-strong: $base-orange-200;
$sem-color-accent-2-strongest: $base-orange-700;

$sem-color-accent-3-weak: $base-yellow-400;
$sem-color-accent-3-moderate-default: $base-yellow-500;
$sem-color-accent-3-strong: $base-yellow-600;
$sem-color-accent-3-tint-weak: $base-yellow-50;
$sem-color-accent-3-tint-moderate: $base-yellow-100;
$sem-color-accent-3-tint-strong: $base-yellow-200;
$sem-color-accent-3-strongest: $base-yellow-900;

$sem-color-accent-4-weak: $base-purple-400;
$sem-color-accent-4-moderate-default: $base-purple-600;
$sem-color-accent-4-strong: $base-purple-700;
$sem-color-accent-4-tint-weakest: $base-purple-25;
$sem-color-accent-4-tint-weak: $base-purple-50;
$sem-color-accent-4-tint-moderate: $base-purple-100;
$sem-color-accent-4-tint-strong: $base-purple-200;
$sem-color-accent-4-strongest: $base-purple-800;

$sem-color-canvas-background: $base-neutral-25;
$sem-color-text-strong: $base-neutral-900;
$sem-color-text-strong-invert: $base-neutral-0;
$sem-color-text-weak: $base-neutral-400;
$sem-color-icon-strong: $base-neutral-900;
$sem-color-icon-weak: $base-neutral-400;
$sem-color-icon-weakest: $base-neutral-200;
$sem-color-outline-weak: $base-neutral-50;
$sem-color-outline-moderate: $base-neutral-75;
$sem-color-outline-strong: $base-neutral-100;
$sem-color-background-clear: $base-neutral-0;
$sem-color-background-clear-invert: $base-neutral-900;
$sem-color-background-cloudy: $base-neutral-50;
$sem-color-hover-default: $base-navy-100;

$com-title-bar-color-background: $sem-color-background-clear;
$com-title-bar-color-text-weak: $sem-color-text-weak;
$com-title-bar-color-text-strong: $sem-color-text-strong;

$com-input-color-background: $sem-color-background-clear;
$com-input-color-background-active: $sem-color-background-clear;
$com-input-color-background-error: $sem-color-error-tint-moderate;
$com-input-color-background-success: $sem-color-success-tint-moderate;
$com-input-color-outline: $sem-color-outline-moderate;
$com-input-color-outline-active: $sem-color-primary-moderate-default;
$com-input-color-outline-success: $sem-color-success-moderate-default;
$com-input-color-outline-error: $sem-color-error-moderate-default;
$com-input-color-text-active: $sem-color-text-strong;
$com-input-color-text-placeholder: $sem-color-text-weak;
$com-input-color-text-error: $sem-color-error-strong;
$com-input-color-text-success: $sem-color-success-strongest;
$com-input-color-icon-active: $sem-color-icon-strong;
$com-input-color-icon-placeholder: $sem-color-icon-weak;
$com-input-color-icon-error: $sem-color-error-strong;
$com-input-color-icon-success: $sem-color-success-strongest;

$com-btn-lg-color-primary-background: $sem-color-primary-moderate-default;
$com-btn-lg-color-primary-hover: $sem-color-primary-strongest;
$com-btn-lg-color-primary-foreground: $sem-color-text-strong-invert;
$com-btn-lg-color-secondary-background: $sem-color-background-clear;
$com-btn-lg-color-secondary-background-hover: $sem-color-neutral-tint-weakest;
$com-btn-lg-color-secondary-foreground: $sem-color-text-strong;
$com-btn-lg-color-secondary-outline: $sem-color-neutral-moderate-default;

$com-btn-sm-color-primary-background: $sem-color-primary-moderate-default;
$com-btn-sm-color-primary-hover: $sem-color-primary-strongest;
$com-btn-sm-color-primary-foreground: $sem-color-text-strong-invert;
$com-btn-sm-color-secondary-background: $sem-color-background-clear;
$com-btn-sm-color-secondary-background-hover: $sem-color-neutral-tint-weakest;
$com-btn-sm-color-secondary-foreground: $sem-color-text-strong;
$com-btn-sm-color-secondary-outline: $sem-color-neutral-moderate-default;

$com-btn-icon-text-color-foreground: $sem-color-text-strong;
$com-btn-icon-text-color-background-hover: $sem-color-neutral-tint-weak;

$com-status-pills-neutral-background: $base-neutral-25;
$com-status-pills-neutral-foreground: $com-title-bar-color-text-strong;
$com-status-pills-warning-background: $sem-color-accent-2-tint-weak;
$com-status-pills-warning-foreground: $sem-color-accent-2-strongest;
$com-status-pills-intermediary-background: $sem-color-secondary-tint-weak;
$com-status-pills-intermediary-foreground: $sem-color-secondary-strong;
$com-status-pills-success-background: $sem-color-success-tint-weak;
$com-status-pills-success-foreground: $sem-color-success-strongest;
$com-status-pills-danger-background: $sem-color-error-tint-weak;
$com-status-pills-danger-foreground: $sem-color-error-strong;

$com-tab-navigation-background: $sem-color-background-clear;
$com-tab-navigation-foreground-inactive: $com-title-bar-color-text-weak;
$com-tab-navigation-foreground-active: $sem-color-primary-moderate-default;
$com-tab-navigation-background-hover: $sem-color-primary-tint-moderate;
$com-tab-navigation-foreground-hover: $sem-color-primary-moderate-default;

$com-checkbox-background-unchecked: $sem-color-background-clear;
$com-checkbox-background-unchecked-hover: $sem-color-primary-tint-weak;
$com-checkbox-background-unchecked-error: $sem-color-error-tint-moderate;
$com-checkbox-background-checked: $sem-color-primary-strong;
$com-checkbox-background-checked-hover: $sem-color-primary-weak;
$com-checkbox-outline-unchecked: $sem-color-outline-moderate;
$com-checkbox-outline-unchecked-hover: $sem-color-primary-tint-moderate;
$com-checkbox-outline-checked: $sem-color-primary-strong;
$com-checkbox-outline-checked-hover: $sem-color-primary-weak;
$com-checkbox-outline-error: $sem-color-error-moderate-default;
