@import '../variables/colors.scss';
@import '../variables/colors_v2.scss';

$orange_light: #f59d29;
$color-grey-border: #e8e8e8;

.cdk-overlay-container {
    z-index: 1052 !important;
}

.mat-mdc-option.mdc-list-item {
    background-color: $color-dark-grey;
    color: white;
    padding: 10px;
    font-family: 'Inter', sans-serif;
}

.mat-mdc-option:focus:not(.mat-mdc-option-disabled),
.mat-mdc-option:hover:not(.mat-mdc-option-disabled) {
    background: $color-nav-v2-buttons-active !important;
}

.mat-mdc-option {
    height: 30px !important;
    font-size: 14px;
    min-height: auto !important;
    &:hover:not(.mdc-list-item--disabled) {
        background-color: #313841 !important;
    }
    &.wide-tiles {
        height: 60px !important;
        padding: 0 3px;
        .mdc-list-item {
            &__primary-text {
                padding-top: 10px;
                width: 100%;
            }
        }
    }
}

.mdc-list-item {
    &__primary-text {
        font-family: 'Inter', sans-serif !important;
    }
}

.mat-mdc-option.mdc-list-item {
    &__primary-text {
        white-space: nowrap !important;
        text-overflow: ellipsis !important;
        overflow: hidden !important;
    }
    &:not(.mdc-list-item--selected) {
        .mdc-list-item__primary-text {
            color: #fff;
            letter-spacing: 0;
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;
        }
    }
    &.mdc-list-item--selected {
        .mdc-list-item__primary-text {
            color: $color-dark-grey !important;
        }
    }
    .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-minimal::after {
        color: $color-dark-grey;
    }
}

.mat-mdc-option.mat-mdc-option-disabled {
    color: rgba(255, 255, 255, 1);
}
.mat-mdc-option:focus:not(.mat-mdc-option-disabled),
.mat-option:hover:not(.mat-mdc-option-disabled) {
    background: rgba($orange_light, 0.2);
}

.mat-mdc-option.mat-active {
    background: $color-sub-menu-v2-hover !important;
    color: $color-white;
}

.mat-mdc-slider {
    .mdc-slider {
        &__thumb-knob {
            background-color: $sem-color-icon-strong !important;
            border: 0 !important;
        }
        &__track {
            height: 2px !important;
            &--active {
                height: 2px !important;
                &_fill {
                    border-color: $sem-color-icon-strong !important;
                }
            }
            &--inactive {
                height: 2px !important;
                background-color: #d8e0f3 !important;
                opacity: 1 !important;
            }
        }
    }
}

.mat-mdc-chip-listbox {
    .mat-mdc-standard-chip.mdc-evolution-chip--selectable:not(.mdc-evolution-chip--with-primary-icon) {
        background-color: transparent;
    }
    .mat-mdc-chip {
        max-height: 25px;
        font-size: 12px;
        .mdc-evolution-chip__cell--primary {
            background-color: rgba(34, 189, 131, 0.05);
        }
    }
}

.mat-mdc-text-field-wrapper.mdc-text-field--outlined {
    .mat-mdc-form-field-infix {
        padding-top: 4px;
        padding-bottom: 4px;
    }
}

.mat-mdc-form-field {
    font-size: 14px;
    width: 100%;
    .mat-mdc-form-field-infix {
        min-height: unset;
    }
}

.mat-mdc-form-field-label {
    font-weight: bold;
}

.mat-mdc-form-field.mat-focused .mat-mdc-form-field-label {
    color: $color-input-focus !important;
    font-weight: bold;
}

.mat-mdc-form-field-underline {
    display: none;
}

.mat-form-field-appearance-outline .mat-mdc-text-field-wrapper {
    border-radius: 4px !important;
    border: 1px solid $color-grey-border !important;
    background: $color-white;
    & + .mat-mdc-form-field-subscript-wrapper {
        display: none;
    }

    &:focus-within {
        border: 1px solid $color-mango !important;
    }
}

.mdc-text-field--filled:not(.mdc-text-field--disabled) .mdc-line-ripple::before {
    border-bottom: 0;
}

.mat-mdc-input-element {
    color: $color-dark-grey !important;
    font-weight: bold;
    &::placeholder {
        color: $color-disabled-grey !important;
    }
}

.mat-mdc-form-field.mat-form-field-invalid .mat-form-field-label {
    color: $color-mango-border;
}

.mat-placeholder-required {
    display: none;
}

.mat-mdc-form-field-appearance-outline .mat-mdc-form-field-wrapper {
    padding: 0;
}

.mat-mdc-autocomplete-panel .mat-mdc-option.mat-selected:not(.mat-active):not(:hover) {
    background: $color-sub-menu-v2-hover !important;
    color: $color-white !important;
}

.mat-mdc-autocomplete-panel {
    max-height: 220px !important;
    padding: 0px !important;
}

/* Mat form field - outline */
.mat-mdc-form-field-appearance-outline .mat-mdc-form-field-wrapper {
    margin: 0px !important;
}

.mat-mdc-form-field-appearance-outline .mat-mdc-form-field-outline {
    background-color: white;
    border-radius: 4px;
    .mat-mdc-form-field-outline-start {
        border-radius: 4px 0px 0px 4px;
    }
    .mat-mdc-form-field-outline-end {
        border-radius: 0px 4px 4px 0px;
    }
}

.mdc-text-field--outlined:not(.mdc-text-field--disabled).mdc-text-field--focused {
    .mdc-notched-outline__leading,
    .mdc-notched-outline__notch,
    .mdc-notched-outline__trailing {
        border-color: $color-mango;
        border-width: 1px !important;
    }
}

.mdc-text-field--outlined:not(.mdc-text-field--disabled) {
    .mdc-notched-outline__leading,
    .mdc-notched-outline__notch,
    .mdc-notched-outline__trailing {
        border-color: #fff;
        border-width: 1px !important;
    }
}

.mdc-text-field--outlined:not(.mdc-text-field--disabled):not(.mdc-text-field--focused):hover .mdc-notched-outline {
    .mdc-notched-outline__leading,
    .mdc-notched-outline__notch,
    .mdc-notched-outline__trailing {
        border-color: $color-mango;
    }
}

/* Mat chip */
.mat-mdc-chip-row.mat-mdc-standard-chip,
.mat-mdc-chip-option.mat-mdc-standard-chip {
    background: rgba(34, 189, 131, 0.05) !important;
    border-radius: 3px;
    border: solid 1px $color-green-blue;
    color: $color-dark-grey;
    font-size: 12px;
    height: 25px;

    .mdc-evolution-chip__cell--primary {
        overflow: auto;
    }
    .mdc-evolution-chip__action--primary {
        overflow: auto;
    }
    .mat-mdc-chip-action-label {
        overflow: auto;
    }
    .mat-mdc-chip-graphic {
        height: unset;
    }
}

.mat-mdc-form-field-icon-prefix,
.mat-mdc-form-field-icon-suffix {
    .mat-icon {
        padding: 4px !important;
        font-size: 22px;
    }
}
