$color-ice-blue: #dddfe2;
$color-nav-v2-bg: #050607;
$color-nav-v2-buttons: #1c2a36;
$color-nav-v2-borders: #131e28;
$color-nav-v2-buttons-active: #313c49;
$color-sub-menu-v2-bottom: #49545e;
$color-sub-menu-v2-hover: #495463;
$color-black-1: #1b1e20;
$color-sub-menu-v2-active: #ebebeb;
$color-mango: #f59d29;
$color-mango-faded: #fefaf4;
$color-mango-border: #ef5b00;
$color-mango-dark: #c24700;
$color-green-blue: #22bd83;
$color-dark-grey: #525d6c;
$color-dark-grey-2: #808488;
$color-dark-grey-3: #eaeff280;
$color-light-grey: #f0f3f4;
$color-light-grey-2: #f6f5f5;
$color-light-grey-3: #cbcbcb;
$color-light-grey-4: #faf8f8;
$color-light-grey-5: #eaeaea;
$color-light-grey-6: #fcf4dd;
$color-light-grey-7: #e2e2e2;
$color-porcelain-grey: #eaeff2;
$color-disabled-grey: #b4bcc8;
$color-disabled-light-grey: #f4f6f7;
$color-disabled-text: #9da5ae;
$color-input-focus: #1c2a36;
$color-tab-selected: #47515f;
$color-shadow: #dddfe2;
$color-educational: #21aeaa;
$color-orange-faded: rgba(239, 91, 0, 0.5);
$colour-hr-dark: #b5bdc9;
$color-mango-hover: rgba(245, 157, 41, 0.1);
$color-ice-blue-faded: rgba(241, 243, 244, 0.5);
$color-primary-green: #036856;
$color-primary-green-dark: #035f4e;
$color-primary-green-light: #27a27b;
$color-educational-green-light: #dbecec;
$color-educational-green-highlight: #abd5d3;
$color-bisque: #ffe0c0;
$color-white: #ffff;
$color-legacy-blue: #71c8e2;
$color-light-green-1: #edf6ef;
$color-light-green-2: #409557;
$color-light-green-3: #327444;
$color-brown: #875112;
$color-green-success: #46a460;
$color-yellow-warning: #fcf4dd;

$font-size-icons: 16px;
$font-size-icon-checkbox: 14px;

/* Sizings */
$padding-content-sides: 50px;
$primary-panel-width: 200px;
$primary-subpanel-width: 320px;
$primary-subpanel-side-padding: 30px;
$toggle-panel-height-v2: 37px;
$sidebar-collapsed-width: 45px;

/* Global Search */
$global-search-nav-height: 64px;
$global-search-background: $color-nav-v2-buttons;

/* Secondary Nav  */
$secondary-nav-bg: rgb(82, 93, 108);
$secondary-nav-menu-bg: rgb(71, 81, 95);
$secondary-nav-left-pad: 75px;

$color-btn-v2-off: rgb(71, 81, 95);
$secondary-nav-btn-hover: rgb(71, 81, 95);
$color-btn-v2-on: rgb(49, 56, 65);

$status-color-default: rgb(61, 70, 82);
$status-color-accepted: $color-green-blue;
$status-color-active: $color-mango-border;

$pill-green: rgba(34, 189, 131, 0.5);
$pill-orange: rgba(245, 157, 41, 0.3);
$pill-grey: rgba(82, 93, 108, 0.2);
$pill-blue: rgba(113, 200, 226, 0.5);

$button-grey-darkest: #2d3947;
$button-grey-dark: #45505f;
$button-grey: #54606f;
$button-grey-faded: #939695;
$button-grey-light: #b4bcc8;
$button-grey-disabled: #b5bdc9;
$button-grey-subtle: #eaeef0;
$button-grey-lightest: #fdf9f7;
$button-secondary-stroked-border: #676c71;
$button-secondary-stroked-text: #2e3237;
$warning-text-color: #d03c1f;
$button-secondary-stroked-hover: #eceded;
